var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0 hideScroll",style:(`display: flex; height: 100vh; flex-direction: ${
    _vm.$vuetify.breakpoint.mobile ? 'column' : 'row'
  }`),attrs:{"fluid":""}},[_c('div',{style:(`display: flex; flex-direction: column; position: relative; flex-grow: ${
      _vm.$vuetify.breakpoint.mobile ? '0' : '1'
    }`),attrs:{"id":"headContainer"}},[_c('presentation-overlay'),(_vm.filterOpen)?_c('filter-dialog',{attrs:{"show":_vm.filterOpen},on:{"closeDialog":_vm.closeFilter}}):_vm._e(),_c('v-sheet',{class:_vm.sheetHeight,staticStyle:{"position":"relative","width":"100%"}},[(!_vm.loading)?_c('v-responsive',{staticStyle:{"height":"100%","width":"100%"}},[(_vm.activeType == 'clip' || _vm.activeType == 'time')?_c('editorPlayer',{directives:[{name:"show",rawName:"v-show",value:(_vm.starttimeLoaded),expression:"starttimeLoaded"}],key:_vm.player_key,staticStyle:{"margin-inline":"auto","margin":"auto"},attrs:{"options":_vm.options}}):_vm._e(),(_vm.activeType == 'map')?_c('div',{staticStyle:{"display":"flex","height":"100%","width":"100%","justify-content":"center","align-items":"center"},attrs:{"id":"mapContainer"}},[(_vm.activeType == 'map')?_c('Map',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"maxHeight":_vm.windowHeight * 0.8,"maxWidth":_vm.windowWidth * 0.8,"value":_vm.points,"hideButtons":true,"disableClicks":true,"url":_vm.map.url}}):_vm._e()],1):_vm._e(),(_vm.activeType == 'text_file')?_c('div',{style:(`margin: auto; margin-top: ${_vm.$vuetify.breakpoint.mobile ? '4em' : '10vh'}; width: 80%; height: ${
            _vm.$vuetify.breakpoint.mobile ? '312.875px' : '80%'
          }`)},[_c('open-text-file',{staticStyle:{"margin-top":"6%"},attrs:{"text_file_id":_vm.textId,"presentation":"true"}})],1):_vm._e(),(_vm.activeType == 'filter')?_c('v-sheet',{style:(`display: flex; align-items: center; justify-content: center; margin: auto; margin-top: ${_vm.$vuetify.breakpoint.mobile ? '4em' : '10vh'}; width: 100%; height: ${
            _vm.$vuetify.breakpoint.mobile ? '312.875px' : '80%'
          }`)},[(_vm.activeType == 'filter')?_c('v-card',{staticStyle:{"width":"250px"}},[_c('v-card-title',{},[_vm._v(" "+_vm._s(_vm.$t('presentation.filter'))+" "+_vm._s(_vm.activeItem.name)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('presentation.open_filter'))+" ")]),_c('v-card-actions',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-btn',{on:{"click":_vm.openFilter}},[_vm._v(" "+_vm._s(_vm.$t('presentation.open'))+" ")])],1)],1):_vm._e()],1):_vm._e(),_c('Comment')],1):_vm._e(),(_vm.loading)?_c('v-sheet',{style:(`
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${
            _vm.$vuetify.breakpoint.mobile ? '312.875px' : '100%'
          };
          width: 100%;
        `)},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e()],1),_c('v-sheet',{class:_vm.$vuetify.breakpoint.mobile ? 'mobile-bar' : '',staticStyle:{"z-index":"2","width":"100%"}},[(
          (_vm.activeType == 'clip' && !_vm.loading) ||
          (_vm.activeType == 'time' && !_vm.loading)
        )?_c('editor-bar',{attrs:{"times":_vm.preseRawData}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.activeType == 'clip' && _vm.loading) ||
          (_vm.activeType == 'time' && _vm.loading)
        ),expression:"\n          (activeType == 'clip' && loading) ||\n          (activeType == 'time' && loading)\n        "}],staticStyle:{"height":"100px"}}),_c('bar',{attrs:{"hideTimes":"true","presentation":"true","showCommentBtn":"true"}})],1)],1),_c('v-sheet',{class:_vm.$vuetify.breakpoint.mobile ? 'mobile-bar' : '',staticStyle:{"overflow-y":"auto"},style:({ width: _vm.barSheetWidth })},[_c('presentation-side-bar',{attrs:{"folderId":this.id}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }